import classNames from 'classnames';
import { IoMdMoon } from 'react-icons/io';

import { AppConfig } from '../config/AppConfig';

type ILogoProps = {
  className?: string;
};

const Logo = ({ className }: ILogoProps) => {
  return (
    <div
      className={classNames('mx-5 flex items-center font-semibold', className)}
    >
      <div className="mr-2">
        <IoMdMoon />
      </div>
      <div>{AppConfig.site_name}</div>
    </div>
  );
};

export { Logo };
