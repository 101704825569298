import { useConfig } from '@drugfreesleep/config/useConfig';

import { getApolloClient } from '../graphql/apollo';
import { NoteAtVersionDocument } from '../graphql/generated-types';
import useMarkdown from './useMarkdown';

export const useRenderedNote = (id: number) => {
  const client = getApolloClient();
  const data = client.readQuery({
    query: NoteAtVersionDocument,
    variables: {
      id,
    },
  });
  const note = data?.note;
  const markdown = useMarkdown('');
  return markdown(note?.payload.md || '');
};

export const useComponent = (slug: string) => {
  const config = useConfig();
  const component = config?.components[slug];
  return useRenderedNote(component?.id);
};

export const ManagedComponent = ({ slug }: { slug: string }) => {
  const component = useComponent(slug);
  return <>{component}</>;
};
