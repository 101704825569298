import type { ReactNode } from 'react';

import { Logo } from '../../template/newLogo';

type IFooterTwoRowsCopyrightProps = {
  siteName: string;
  children: ReactNode;
};

const FooterTwoRowsCopyright = (props: IFooterTwoRowsCopyrightProps) => (
  <>
    {props.children}

    <div className="mt-10 flex flex-col items-center justify-between gap-4 border-t border-gray-300 pt-10 text-center sm:flex-row sm:text-left">
      <Logo className="text-blue-800" />

      <div className="text-sm text-stone-700">
        {`© Copyright ${new Date().getFullYear()} ${
          props.siteName
        }. All Rights Reserved.`}
      </div>
    </div>
  </>
);

export { FooterTwoRowsCopyright };
