'use client';

import { AccessCodeEnforcer } from '@drugfreesleep/modules/authentication/AccessCodeEnforcer';
import { ManagedComponent } from '@drugfreesleep/modules/markdown/useRenderedNote';

import { Meta } from '../components/layout/Meta';
import { AppConfig } from '../config/AppConfig';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

const Base = () => {
  return (
    <AccessCodeEnforcer>
      <div className="text-gray-600 antialiased">
        <Meta title={AppConfig.title} description={AppConfig.description} />
        <Navbar />
        <ManagedComponent slug="homepage" />
        <Footer />
      </div>
    </AccessCodeEnforcer>
  );
};

export { Base };
